import * as React from 'react'
import { Link as ScrollLink } from 'react-scroll'

import { Container } from '../Container'
import { UnsplashCreditBadge } from '../UnsplashCreditBadge'

import styles from './Intro.module.scss'

import nazdarEmblem from '../../../public/Nazdar-Logo.png'

export const Intro = () => (
  <div className={styles.intro}>
    <div className={styles.introInner}>
      <Container>
        <img className={styles.nazdarEmblem} src={nazdarEmblem} />

        {/* <h1>Nazdar Games</h1> */}

        {/* <p>As a video game studio that creates games for both mobile and desktop systems, we are passionate about providing  with an immersive and enjoyable gaming experience. Our</p> */}
        <p>Nazdar Games is an independent video game studio, dedicated to creating innovative and engaging games that stand out.  </p>

        <p>We are driven by our love for gaming and our desire to create unforgettable experiences for players.</p>
        {/* <p>With a focus on innovation, quality, and player satisfaction, we are committed to pushing the boundaries of what is possible in video game development and delivering games that are loved by players around the world.</p> */}

        {/* <p>Our team is comprised of passionate and experienced game developers, designers, and artists who are committed to bringing unique and original game concepts to life. </p> */}

        {/* <p>As a new company, we are excited to push the boundaries of what is possible in the gaming industry and to establish ourselves as a force to be reckoned with. </p> */}
        {/* <p>Our goal is to build a loyal community of gamers who appreciate our commitment to quality, creativity, and fun.

        </p> */}

        <p>We believe that the future of gaming is bright, and we are excited to be a part of it.
        </p>

      </Container>
    </div>
    <ScrollLink className={styles.introScrollThingy} to="features" smooth offset={-60}>
      &#9660; learn more &#9660;
    </ScrollLink>
    <div className={styles.credit}>
      <UnsplashCreditBadge
        src="https://unsplash.com/@levibare1?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
        author="Levi Bare"
      />
    </div>
  </div>
)
