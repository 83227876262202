import * as React from 'react'
import classnames from 'classnames'

import { Container } from '../Container'
import { Row } from '../Row'
import { Col } from '../Col'
import { FullScreenSection } from '../FullScreenSection'
import { UnsplashCreditBadge } from '../UnsplashCreditBadge'

import styles from './Features.module.scss'

import RucksackMagazine from './rucksack-magazine-386319.jpg'
import RyanSearle from './ryan-searle-345035.jpg'

import appstore from '../../../public/appstore.svg'
import leap1 from '../../../public/leap-1.jpg'
import leap2 from '../../../public/leap-2.jpg'
import leap3 from '../../../public/leap-3.jpg'
import leap4 from '../../../public/leap-4.jpg'


export const Features = () => (
  <FullScreenSection name="features">
    <Container>
      <Row style={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
        <Col>
          <h1>OUR GAMES</h1>

          <div className={classnames(styles.featureBox, styles.guessWhat)}>


            <h2 className={styles.center} style={{ color: 'black' }}>Leaping Legends</h2>
            <p style={{ marginBottom: '0' }} className={styles.center}>Release date: <span className={styles.leapName} >Coming Soon</span></p>
            <p className={styles.center}>Platform(s): <span className={styles.leapName}>iPhone / iPad / MacOS</span></p>

            <img className={classnames(styles.leapImage, styles.mobileOnly)} alt="Leap 1" src={leap1} />


            <p>
              <span className={styles.leapName}>Leaping Legends</span> is a retro-style platformer that challenges players to conquer 48 levels across 6 worlds.
            </p>

            <p>  Easy to pick up for the casual gamer, but with layers of depth for hardcore players and completionists.
            </p>

            <p>
              Play as one of 12 Legends, heroes with unique abilities, and defeat dozens of different enemies to rescue the king.
            </p>

            <p>
              Embark on a heroic journey, save the kingdom, and become a legend in your own right in this thrilling side-scroller.
            </p>

            <p>Features:</p>
            <ul>

              <li>
                Family-friendly, ad-free experience
              </li>
              <li>
                Support for iOS, ipadOs, and MacOS
              </li>
              <li>Bluetooth controller support</li>


            </ul>

            {/* <p className={styles.center}>Try the beta today</p>
            <a className={styles.appstoreLink} target="_blank" href='https://testflight.apple.com/join/JNawmXFL'>

              <img className={styles.appstore} src={appstore} />
            </a> */}

            {/*
            <p>
              <small>
                <sup>[1]</sup> Okay, listen up here. HTML5 adoption in browsers has reached the tipping point. Unless you&apos;re one of
                those weirdos still using Windows fucking XP, then every shitty browser there is ― Edge, Safari, even Internet fucking
                Explorer ― has implemented the{' '}
                <a href="https://caniuse.com/" target="_blank" rel="noopener noreferrer">
                  core bits of HTML5
                </a>
                . No fucking excuses.
              </small>
            </p> */}
          </div>
        </Col>
        <Col>
          <div className={styles.featureImageBox}>
            <img className={classnames(styles.leapImage, styles.desktopOnly)} alt="Leap 1" src={leap1} />
            <img className={styles.leapImage} alt="Leap 2" src={leap2} />
            <img className={styles.leapImage} alt="Leap 3" src={leap3} />
            <img className={styles.leapImage} alt="Leap 4" src={leap4} />

          </div>
        </Col>
      </Row>
      {/* <Row reverse style={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
        <Col>
          <div className={styles.featureBox}>
            <h2>MODERN JAVASCRIPT WITHOUT THE HEADACHE.</h2>
            <p>
              The JavaScript ecosystem is exploding like uncontained diarrhea, and in the middle of that, the overall page size on the Web
              also increased. So you might think that websites will load slower than ever, shit will break, and we&apos;re inching one step
              closer to the heat death of the universe.
            </p>
            <p>
              <strong>Wrong, motherfucker</strong>.
            </p>
            <p>
              Look at how fast this shit loads. It&apos;s faster than you can even take a shit. This website is rendered with{' '}
              <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">
                Gatsby.js
              </a>
              , a fawesome static-site generator powered by React which shits out pre-rendered static pages with extra optimization built
              in.
            </p>
            <p>
              Even better than that, despite all of this newfangled ECMABabelFlowReasonTypeScript magic, you <em>can</em> still make things
              less suck. Strap yourselves in, folks.
            </p>
          </div>
        </Col>
        <Col>
          <div className={styles.featureImageBox}>
            <img alt="Ryan Searle" src={RyanSearle} />
            <div className={styles.credit}>
              <UnsplashCreditBadge
                src="https://unsplash.com/@ryansearle?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
                author="Ryan Searle"
              />
            </div>
          </div>
        </Col>
      </Row> */}
      {/* <Row style={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
        <Col>
          <div className={styles.featureBox}>
            <h2>PERFORMANCE WITHOUT THE UNNECESSARY DIET.</h2>
            <p>
              Think your website is fat? Been thinking to take your website on an unnecessary diet? Stop the fuck right there. Your
              motherfucking website looks fine as it is, don&apos;t let anybody fucking tell you otherwise.
            </p>
            <p>
              But &quot;page bloat&quot;, you say? Sure, back in ye olde Web 2.0 days, this was a problem. But the thing is, on the modern
              web, page bloat has become much less of a fucking issue than whether or not we should put in pineapple on a fucking pizza
              <sup>
                <small>[2]</small>
              </sup>
              .
            </p>
            <p>
              Especially when newfangled tools and techniques that makes it easier to optimize for that sweet, sweet Lighthouse score, that
              sweet, sweet first meaningful paint (FMP) time. Here&apos;s the key to all of these black magic:{' '}
              <strong>progressive enhancement</strong>.
            </p>
            <p>
              <small>
                <sup>[2]</sup> Well hey, I don&apos;t care whatever the fuck you do to your pizza, I&apos;m not your mom. So if it tastes
                good for you, then... why the hell not?
              </small>
            </p>
          </div>
        </Col>
        <Col style={{ position: 'relative' }}>
          <div className={classnames(styles.featureBox, styles.alternate)}>
            <p>
              Even when you got a shitload of scripts and fonts, you can wrap all the critical ones inside a{' '}
              <code>&lt;link rel=&quot;preload&quot;&gt;</code> tag. Thanks to{' '}
              <a href="https://en.wikipedia.org/wiki/HTTP/2" target="_blank" rel="noopener noreferrer">
                HTTP/2
              </a>
              , it will load all of them in parallel. Anything else, like below-the-fold images and scripts for other pages, can be
              pre-cached or lazyloaded.
            </p>
            <p>
              If your site has got a bunch of CSS shoved into it, <strong>only load what&apos;s critical for the page</strong>, and inline
              them for good measure. If your CSS load blocks the rendering of your page, fuck you.
            </p>
            <p>
              And should you ever get lost, the experts have been there and done that. Google has their own{' '}
              <a href="https://developers.google.com/web/fundamentals/performance/prpl-pattern/" target="_blank" rel="noopener noreferrer">
                PRPL
              </a>{' '}
              method, and the Smashing Magazine has their own{' '}
              <a
                href="https://www.smashingmagazine.com/2019/01/front-end-performance-checklist-2019-pdf-pages/"
                target="_blank"
                rel="noopener noreferrer"
              >
                performance checklist
              </a>
              , both of which are worth checking out.
            </p>
          </div>
        </Col>
      </Row> */}
    </Container>

  </FullScreenSection>
)
